<template>
  <div class="row">
    <loading-notification :show="loading" />
    <retry-notification :show="error" @retry="updateData()" />
    <div class="flex xs12">
      <va-card :title="$t('teams.edit')">
        <actions
          slot="actions"
          crud-links="teams"
          :actions="actions"
          :action-data="{ id: $route.params.id }"
        />
        <tabs-container :tabs="tabs">
          <template v-slot:data>
            <data-form :team="team" :loading="loading" @submit="submit" />
          </template>
          <template v-slot:members>
            <members-table
              :edit="true"
              :team="team"
              :loading="loading"
              @submit="submit"
            />
          </template>
        </tabs-container>
      </va-card>
    </div>
  </div>
</template>

<script>
const TabsContainer = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/TabsContainer");
const Actions = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Actions");
const MembersTable = () => import(/* webpackPrefetch: true */ "./Members");
const DataForm = () => import(/* webpackPrefetch: true */ "./Form");

export default {
  name: "teams-edit",
  components: {
    TabsContainer,
    Actions,
    DataForm,
    MembersTable,
  },
  data() {
    return {
      actions: ["index", "view", "delete"],
      error: false,
      loading: false,
      team: {},
    };
  },
  computed: {
    tabs() {
      const t = [
        { title: this.$t("teams.tabs.data.title"), name: "data" },
        { title: this.$t("teams.tabs.members.title"), name: "members" },
      ];

      return t;
    },
  },
  created() {
    this.updateData();
  },
  methods: {
    // FIXME
    routeBuilder(id) {
      return `teams/${id}`;
    },
    routeBuilder2(id) {
      return `/teams/equipments/update/${id}`;
    },
    async updateData() {
      this.loading = true;
      this.error = false;

      let u = false;
      const teamId = this.$route.params.id;
      try {
        u = await this.$http.get(this.routeBuilder(teamId));
      } catch (err) {
        console.error("Error fetching team data", err);
        this.error = true;
        this.loading = false;
        return;
      }

      this.loading = false;
      this.team = u.data.data;
    },
    async submit(team) {
      this.loading = true;

      try {
        if (team.users) {
          await this.updateTeam(team, team.id);
          // await this.postTeamYearPlan(team, team.id);
          return this.teamHistory(team);
        } else {
          this.validateTeam(team);
          const latestTeamYearPlan = this.getLatestTeamYearPlan(team);
          await this.updateTeam(team, latestTeamYearPlan);
          // await this.postTeamYearPlan(latestTeamYearPlan);
          if (team.users) return this.teamHistory(team);
          this.showSuccessToast();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    validateTeam(team) {
      team.responsible = team.responsible_id;
      if (team.equipment) {
        this.checkResponsibleDefined(team);
        this.checkEquipmentSpecificRules(team);
        this.checkDateResponsibleRequired(team);
      } else {
        team.equipment = 4;
      }
    },

    checkDateResponsibleRequired(team) {
      if (!team.date_responsible && team.equipment != 4) {
        throw new Error("notification.error.date_responsible_required");
      }
    },

    checkEquipmentSpecificRules(team) {
      if (team.equipment == 4) {
        team.responsible_id = "";

        if (team.responsible) {
          throw new Error(
            "notification.error.responsible_not_required_for_equipement"
          );
        }

        if (team.date_responsible) {
          throw new Error(
            "notification.error.date_responsible_not_required_for_equipement"
          );
        }
      }
    },

    checkResponsibleDefined(team) {
      if (team.equipment != 4 && (!team.responsible || !team.responsible_id)) {
        throw new Error("notification.error.responsible_required");
      }
    },

    getLatestTeamYearPlan(team) {
      return team.teams_year_plans[team.teams_year_plans.length - 1];
    },

    async updateTeam(team, latestTeamYearPlan) {
      team.teams_year_plans = latestTeamYearPlan;
      await this.$http.put(this.routeBuilder(team.id), team);
    },

    async postTeamYearPlan(latestTeamYearPlan) {
      const id = latestTeamYearPlan.id;
      await this.$http.post(this.routeBuilder2(id), latestTeamYearPlan);
    },

    showSuccessToast() {
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
    },

    handleError(error) {
      console.error("Error processing request", error);
      this.showToast(this.$t(error.message), {
        icon: "fa-times",
        position: "top-right",
      });
    },

    async updateStatusHistory(team) {
      const data = {
        status: team.status,
      }
      try {
        await this.$http.post("teams/status/" + team.id, data);
      } catch (e) {
        this.loading = false;
        return;
      }
    },

    async teamHistory(team) {
      const data = {
        originals: [],
        currents: team.users._ids,
      };

      this.team.users.forEach((m) => {
        data.originals.push(m.id);
      });

      try {
        await this.$http.post("teams/history/" + this.team.id, data);
      } catch (e) {
        this.loading = false;
        return;
      }

      this.loading = false;
      this.showToast(this.$t("notifications.alerts.success"), {
        icon: "fa-check",
        position: "top-right",
      });
    },
  },
};
</script>
